import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BodyComponent } from './body/body.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule if you're using FormBuilder


const routes: Routes = [
  {
    path: '',
    component: BodyComponent,
    children: [
      { path: '', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule) },
      { path: 'signin', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule) },
      // { path: 'change-pwd', loadChildren: () => import('./pages/forget-pwd/forget-pwd.module').then(m => m.ForgetPwdModule) },

      { path: 'my-profile', loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule) },
      { path: 'admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) }, 
      // { path: 'addgenres', loadChildren: () => import('./pages/addgenres/addgenres.module').then(m => m.AddgenresModule) },
      // { path: 'addgenres/:id', loadChildren: () => import('./pages/addgenres/addgenres.module').then(m => m.AddgenresModule) },
    ]
  },
  { path: '**', redirectTo: 'admin'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    ReactiveFormsModule // Import ReactiveFormsModule if you're using FormBuilder
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
