<footer>
    
    <!-- <div class="copyright-wrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" style="padding:20px">
                    <div class="copyright-text text-center">
                        <p>Copyright &copy; 2023. All Rights Reserved By <a [routerLink]="['/']">Pexcel</a></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="payment-method-img text-center text-md-right">
                        <img src="assets/img/images/card_img.png" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</footer>