export const environment = {
  production: false,
  serviceUrl: 'https://prod-pexcelwebservice-api.azurewebsites.net/api',
// serviceUrl: 'https://ottappfunctions.azurewebsites.net/api',
 //serviceUrl: 'https://ottappfunctionsdev.azurewebsites.net/api',
//serviceUrl: 'http://localhost:8070/api',
   Encryptionkey:"tVN0p+0LnGLy8U33RDZ+lg==",
bloburl: "https://pexcelassetstorage.blob.core.windows.net/",
 // bloburl: "https://ottappstorage.blob.core.windows.net/"
};
