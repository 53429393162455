import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyFunctionsService } from 'src/services/functions';
import { WebservicesService } from 'src/services/webapis';
import { DisableNavigationService } from 'src/app/disable-navigation.service';


declare var $;
declare var WOW;
declare var sal;

@Component({
	selector: 'com-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	UserData: any;
	Fname: any;
	Lname: any;
	FullName: any;
	LogName: any;
	token: any;
	showProgressBar:boolean=false;
	constructor(
		public API: WebservicesService,
		private router: Router,
		public CF: MyFunctionsService,
		private disableNavigationService: DisableNavigationService
	) {
	
	 }

	ngOnInit(): void {

		//this.token = localStorage.getItem('IdToken');
		console.log(this.token)

		//if (this.token) {
			this.roles()
		

	//	}

		setTimeout(() => {
			this.bindfunctions();
			this.top_up();
		}, 50);
		  this.API.ProgressBarSubject.subscribe((value:any)=>{
			   this.showProgressBar= value
		  });
		// this.API.GeTProfileDetails().then(r =>{
		// 	this.UserData = r.Value;
		// 	console.log(this.UserData);
		// })

	}


	bindfunctions() {



		/*=============================================
		=    		Mobile Menu			      =
	=============================================*/
		//SubMenu Dropdown Toggle
		if ($('.menu-area li.menu-item-has-children ul').length) {
			$('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
		}
		//Mobile Nav Hide Show
		if ($('.mobile-menu').length) {

			var mobileMenuContent = $('.menu-area .main-menu').html();
			$('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

			//Dropdown Button
			$('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
				$(this).toggleClass('open');
				$(this).prev('ul').slideToggle(500);
			});
			//Menu Toggle Btn
			$('.mobile-nav-toggler').on('click', function () {
				$('body').addClass('mobile-menu-visible');
			});

			//Menu Toggle Btn
			$('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
				$('body').removeClass('mobile-menu-visible');
			});
		}

	}

	top_up() {
		/*=============================================
		=     Menu sticky & Scroll to top      =
	=============================================*/
		$(window).on('scroll', function () {
			var scroll = $(window).scrollTop();
			if (scroll < 245) {
				$("#sticky-header").removeClass("sticky-menu");
				$('.scroll-to-target').removeClass('open');

			} else {
				$("#sticky-header").addClass("sticky-menu");
				$('.scroll-to-target').addClass('open');
			}
		});


		/*=============================================
			=    		 Scroll Up  	         =
		=============================================*/
		if ($('.scroll-to-target').length) {
			$(".scroll-to-target").on('click', function () {
				var target = $(this).attr('data-target');
				// animate
				$('html, body').animate({
					scrollTop: $(target).offset().top
				}, 1000);
			});
		}

	}

	clicksignIn() {
		// alert('hi');
		this.API.Login().then(r => {
			console.log(r.Value);

			if (r != null) {
				(window.location.href = r.Value)
					.then(() => {
						window.location.reload();
					});
				//this.router.navigateByUrl('/admin')
			}
		})
	}

	Userdata() {
		this.API.GeTProfileDetails().subscribe(r => {
			if (r) {
				this.UserData = r.Value;
				let arr = this.UserData.Name.split(' ');
				this.Fname = arr[0];
				this.LogName = r.Value.Name;
			}
		})
	}

	clicksignOut(){

		this.API.Logout().then(r =>{
			console.log(r);
			if(r){
				if (this.disableNavigationService.canDeactivate(null, null, null, null)) {
				localStorage.clear();
				window.location.reload();
			
				//this.clicksignIn();
				}
				  
			}
		})

		// this.API.Login().then(r => {
		// 	console.log(r.Value);
		// 	localStorage.removeItem('IdToken');
		// 	if (r != null) {
		// 		(window.location.href = r.Value)
		// 		.then(() => {
		// 			window.location.reload();
		// 		});
		// 	}
		// })

		// if(!localStorage.getItem('IdToken')){
		// 	this.API.Login().then(r => {
		// 		console.log(r.Value);
		// 		 localStorage.removeItem('IdToken');
		// 		if (r != null) {
		// 			(window.location.href = r.Value)
		// 			.then(() => {
		// 				window.location.reload();
		// 			});
		// 		}
		// 	})
		// }
		// else{
		// 	// window.history.back();
		// 	localStorage.clear();
		// 	this.clicksignIn()
		// 	// window.history.back();
		// }
	}

	async roles() {
		
		// const urlFragment = window.location.hash;
		// const token = urlFragment?.split('=')[1];
		const urlFragment = window.location.hash;
		const urlParams = new URLSearchParams(window.location.hash);
		const token = urlParams.get('#id_token');
		if (token) {
			console.log(this.token)
			const newUrl = window.location.href.split('#')[0];
			console.log(newUrl);
			localStorage.setItem('IdToken', token);
			window.history.replaceState({}, document.title, newUrl)
			
			
			await  this.API.GeTUserRoles1().subscribe((r: any) => {
				console.log(r);
				if (r.Value == true) {
					this.Userdata();
			        this.token = token;
					const newHistory = ['admin']; // Update history based on your needs
					this.API.navigationHistory.next(newHistory);
					this.router.navigateByUrl('/admin')
					//	this.router.navigate(['/admin']);
					//this.CF.SwalError('Successfully login', 'Success');
					//this.router.navigateByUrl('/admin')
				}
				if (r.Value == false) {
					localStorage.removeItem('IdToken');
					const newHistory = ['signin']; // Update history based on your needs
					this.API.navigationHistory.next(newHistory);
					this.router.navigateByUrl('/signin')
					this.CF.SwalError("Unauthorized  Invalid credentials", 'Error');

					// this.API.Login().then(r =>{
					// 	console.log(r);
					// 	if (r != null) {
					// 	  window.location.href = r.Value;
					// 	  // this.router.navigateByUrl('/')
						 
					// 	}
					//   })
				}
				$(".spanner").removeClass("show");
				$(".overlay").removeClass("show");
				document.body.style.overflow = 'auto';
			})
		}
		else if(localStorage.length>0 && localStorage.getItem("IdToken")){
			//
			$(".spanner").removeClass("show");
			$(".overlay").removeClass("show");
			document.body.style.overflow = 'auto';
			this.token = localStorage.getItem("IdToken");
			this.Userdata();
			//this.router.navigateByUrl('/admin')
		  }
		  else{
		    this.router.navigateByUrl('/signin')
		}
	}
}
