import { Injectable } from '@angular/core';
// import { HttpClient} from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/internal/operators/tap';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MyFunctionsService } from './functions';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WebservicesService {

  public serviceUrl = environment.serviceUrl;
  Token: any;
  token: any;
  showProgressBar:boolean=false;
  public navigationHistory = new Subject<string[]>();
  public ProgressBarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public history$ = this.navigationHistory.asObservable();
  // PageSize , PageIndex , SearchByText 
  constructor(
    public httpClient: HttpClient,
    public CF:MyFunctionsService,
    private router: Router,
  ) { 
    const Token = localStorage.getItem('IdToken')
   // console.log(Token);
    // if(Token){
    //   this.Roles();
    // }
  }

  updateHistory(newHistory: string[]) {
    this.navigationHistory.next(newHistory);
  }
  getprogressbar(): any {
	  return this.ProgressBarSubject.value;
	}
  
	setprogressbar(value: any) {

	  this.ProgressBarSubject.next(value);
	}

   public  GetGenres(size,index,serachtext) {
    const url = `${this.serviceUrl}/Genres?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
    return this.httpClient.get<any>(url).pipe(
      tap(d => console.log(d)),
      map(d => (d.Status_cd !== "0") ? d : 'failed')
    )
  }
  public GetAllGenres() {
    const url = `${this.serviceUrl}/Genres?role=admin`;
    return this.httpClient.get<any>(url).pipe(
      tap(d => console.log(d)),
      map(d => (d.Status_cd !== "0") ? d : 'failed')
    )
  }
  public GetSubGenres(size,index,serachtext,GenreParentId) {
    
    const url = `${this.serviceUrl}/SubGenres?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}&GenreParentId=${GenreParentId}`;
    return this.httpClient.get<any>(url).pipe(
      tap(d => console.log(d)),
      map(d => (d.Status_cd !== "0") ? d : 'failed')
    )
  }
  public getSubGenres(GenreParentId) {
    const url = `${this.serviceUrl}/SubGenres?GenreParentId=${GenreParentId}`;
    return this.httpClient.get<any>(url).pipe(
      tap(d => console.log(d)),
      map(d => (d.Status_cd !== "0") ? d : 'failed')
    )
  }
  public Getadvertsements() {
    const url = `${this.serviceUrl}/Advertisement`;
    return this.httpClient.get<any>(url).pipe(
      // tap(d => console.log(d)),
      map(d => (d.Status_cd !== "0") ? d : 'failed')
    )
  }

  public UpdateGenreasapi(formData): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Genres`;
        return this.httpClient.put<any>(url, formData)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  // https://ottappfunctions.azurewebsites.net/api/Genres
  
  public DeleteGenres(id): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Genres?Id=${id}`;
        return this.httpClient.delete<any>(url)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public DeleteSubGenres(id): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/SubGenres?Id=${id}`;
        return this.httpClient.delete<any>(url)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public Video(form_data): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/DeleteJobAndUpdateMetaData`;
        return this.httpClient.post<any>(url, form_data)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  public DeleteVideo(id): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/DeleteVideo?Id=${id}`;
        return this.httpClient.delete<any>(url)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public DeleteDemoVideo(id): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/DemoVideos?Id=${id}`;
        return this.httpClient.delete<any>(url)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  public async InsertAdvertisements(formData): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Advertisement`;
        return this.httpClient.post<any>(url, formData)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  // public CreateAsset(form_data, name): Promise<any> {
  //   try {
  //     return new Promise((resolve, reject) => {
  //       const url = `https://ottappfunctions.azurewebsites.net/api/CreateAssetAndReturnContainerDetails?FileName=${name}`;
  //       return this.httpClient.post<any>(url, form_data, name)
  //         .pipe(
  //           tap(d => console.log(d)),
  //           map(d => (d) ? d : false))
  //         .subscribe((r: any) => resolve(r), reject);
  //     }).catch(err => console.log(err));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

    public upload2(jobDetailsUrl: any) {
      return this.httpClient.get<any>(jobDetailsUrl,
        { reportProgress: true, observe: "events" }
      );
    }



    public Subscription(size,index,serachtext) {
      try {
          const url = `${this.serviceUrl}/Subscriptionplans?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }

    public DeleteSubscription(id): Promise<any> {
      try {
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/Subscriptionplans?Id=${id}`;
          return this.httpClient.delete<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public getVideos(size,index,serachtext) {
      try {
          const url = `${this.serviceUrl}/VideoMetaData?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }
    public getfilteredVideos(Id,SubId,Age) {
      try {
          const url = `${this.serviceUrl}/VideoMetaData?GenreId=${Id}&SubGenreId=${SubId}&AgeGroup=${Age}`;
          return this.httpClient.get<any>(url)
            .pipe(
              tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }
    public SaveDisplayOrderVideos(form_data): Promise<any> {
      try {
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/VideoMetaData`;
          return this.httpClient.post<any>(url, form_data)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
  
    public getDemoVideos(size,index,serachtext) {
      try {
          const url = `${this.serviceUrl}/DemoVideos?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }

    public getadvertisements(size,index,serachtext) {
      try {
       
          const url = `${this.serviceUrl}/Advertisement?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }

    public Deleteadvertisements(id): Promise<any> {
      try {
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/Advertisement?Id=${id}`;
          return this.httpClient.delete<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }

    public getCoupons(size,index,serachtext){
      try {
      
          const url = `${this.serviceUrl}/Coupons?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }
    public CheckCouponExist(Code,Id){
      try {
      
          const url = `${this.serviceUrl}/Coupons?Code=${Code}&Id=${Id}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }

    public DeleteCoupons(id): Promise<any> {
      try {
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/Coupons?Id=${id}`;
          return this.httpClient.delete<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }


    public GetContacts(size,index,serachtext){
      try {
          const url = `${this.serviceUrl}/customerqueries?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }
    public deleteContact(id): Promise<any> {
      try {
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/customerqueries?Id=${id}`;
          return this.httpClient.delete<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public GetFaq(size,index,serachtext){
      try {
      
          const url = `${this.serviceUrl}/FrequentlyAskedQuestions?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
      
      } catch (error) {
        console.log(error);
      }
    }
    public Deletefaq(id): Promise<any> {
      try {
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/FrequentlyAskedQuestions?Id=${id}`;
          return this.httpClient.delete<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public GetTerms(size,index,serachtext) {
      try {
       
          const url = `${this.serviceUrl}/TermsAndConditions?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
            
      } catch (error) {
        console.log(error);
      }
    }
    public Deleteterms(id){
      try {
     
          const url = `${this.serviceUrl}/TermsAndConditions?Id=${id}`;
          return this.httpClient.delete<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
            
      } catch (error) {
        console.log(error);
      }
    }
    public GetPrivacy(size,index,serachtext) {
      try {
      
          const url = `${this.serviceUrl}/Privacypolicy?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
            
      } catch (error) {
        console.log(error);
      }
    }
    public DeletePrivacy(id): Promise<any> {
      try {
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/Privacypolicy?Id=${id}`;
          return this.httpClient.delete<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public async GetAllSettings(): Promise<any> {
      
      try {
        const token = localStorage.getItem('IdToken');
        // console.log(token)
  
        const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
        });
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/ConfigSettings`;
          return this.httpClient.get<any>(url, {headers})
            .pipe(
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public GetAllUsers(size,index,serachtext,age,role) {
      try {
      
          const url = `${this.serviceUrl}/UserManagement?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}&AgeGroup=${age}&UserRole=${role}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
            
      } catch (error) {
        console.log(error);
      }
    }
    public DownloadAllUsers(serachtext,age,role) {
      try {
      
          const url = `${this.serviceUrl}/UserManagement?&SearchByText=${serachtext}&AgeGroup=${age}&UserRole=${role}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
            
      } catch (error) {
        console.log(error);
      }
    }
    public GetAgeGroupAnalysis() {
      try {
      
          const url = `${this.serviceUrl}/DataAnalysis`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
            
      } catch (error) {
        console.log(error);
      }
    }
    public GetGenreAnalysis(age) {
      try {
      
          const url = `${this.serviceUrl}/DataAnalysis?AgeGroup=${age}`;
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
            
      } catch (error) {
        console.log(error);
      }
    }
    public getHistoryVideos(Id,Age,SDate,EDate,size,index,serachtext) {
      try {
        const url = `${this.serviceUrl}/DataAnalysis?watchhistory=true&GenreId=${Id}&Age=${Age}&SDate=${SDate}&EDate=${EDate}&PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}`;
        return this.httpClient.get<any>(url)
            .pipe(
              tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }
    public async Login(): Promise<any> {
      try {
        // const headers = new HttpHeaders({
        //   Authorization: 'bearer ' + this.Token
        // });
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/userflowurl?userrole=admin`;
          // console.log(url)
          return this.httpClient.get<any>(url)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public async GeTUserRoles(): Promise<any> {
      try {
        const token = localStorage.getItem('IdToken');
        // console.log(token)
  
        const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
        });
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/userRole?userrole=admin`;
          return this.httpClient.get<any>(url, {headers})
            .pipe(
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public  GeTUserRoles1() {
      try {
        const token = localStorage.getItem('IdToken');
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
          const url = `${this.serviceUrl}/userRole?userrole=admin`;
          return this.httpClient.get(url, {headers} )
            .pipe(
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }

    public  GeTProfileDetails() {
      try {
  
        const token = localStorage.getItem('IdToken');
        // console.log(token)
  
        const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
        });
  
          const url = `${this.serviceUrl}/UserProfile`;
          // console.log(url)
          return this.httpClient.get<any>(url, {headers})
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false));
      } catch (error) {
        console.log(error);
      }
    }
    public async EditUserDetails(body): Promise<any> {
      try {
        const token = localStorage.getItem('IdToken');
        // console.log(token)
  
        const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
        });
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/userflowurl?userrole=admin`;
          return this.httpClient.put<any>(url, {headers},body)
            .pipe(
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    Roles() {
      
      const urlFragment = window.location.hash;
      const token = urlFragment?.split('=')[1];
      this.token= this.Token;
      // console.log(this.token);
      if(token){
        this.token= token;
        // console.log(this.token)
        localStorage.setItem('IdToken', this.token);
        // if (localStorage.getItem('IdToken')) {
          const newUrl = window.location.href.split('#')[0];
          // console.log(newUrl);
          window.history.replaceState({}, document.title, newUrl)
            this.GeTUserRoles().then((r: any) => {
            // console.log(r);
              if(r.Value == true){
                // alert('from signin')
                // this.CF.SwalError('Successfully login', 'Success');
                this.router.navigateByUrl('/admin')
                .then(() => {
                  window.location.reload();
                });
              }
              if(r.Value == false){
                // alert('signin')
                  this.router.navigateByUrl('/signin')
                  this.CF.SwalError("Unauthorized Invalid credentials", 'Error');
                  // .then(() => {
                  //   window.location.reload();
                  // });
              }
            })
        // }
        }
   
      }

    public Updatedchekc(id, type): Promise<any> {
      try {
        return new Promise((resolve, reject) => {
          const url = 'https://admin2.maaproperties.com/api/common?id=855&type=news';
          return this.httpClient.put<any>(url, id, type)
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
  
    public async Logout(): Promise<any> {
      try {
        const token = localStorage.getItem('IdToken');
        // console.log(token)
  
        const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
        });

        // console.log(headers);
  
        return new Promise((resolve, reject) => {
          // alert('hi')
          const url = `${this.serviceUrl}/UserSignOut`;
          // console.log(url)
          return this.httpClient.get<any>(url, {headers})
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }


    public async GetGeneres(size,index,serachtext,dataTablesParameters): Promise<any> {
      try {
        let page = dataTablesParameters.start / dataTablesParameters.length;
        let queryStr = `PageIndex=${page}&PageSize=${dataTablesParameters.length}`;
  
        if (dataTablesParameters.orderColumn) {
          queryStr +=
            '&sort=' +
            dataTablesParameters.orderColumn.column +
            ',' +
            dataTablesParameters.orderColumn.dir;
        }
        if (
          dataTablesParameters.search &&
          dataTablesParameters.search.value &&
          dataTablesParameters.search.value.length > 0
        ) {
          queryStr += '&SearchByText=' + dataTablesParameters.search.value;
        }

        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/Genres?PageSize=${size}&PageIndex=${index}&SearchByText=${serachtext}${queryStr}`;
          return this.httpClient.get<any>(url)
            .pipe(
              tap((d) => console.log(d)),
              map((d) => (d ? d : false))
            )
            .subscribe((r: any) => resolve(r), reject);
        }).catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }

    Get_genres() {
      const url = `https://ottappfunctions.azurewebsites.net/api/Genres`;
      return this.httpClient.get<any>(url).pipe(
        tap(d => console.log(d)),
        map(d => (d.Status_cd !== "0") ? d : 'failed')
      )
    }


}
