<!--=====================================-->
<!--=   Scroll-up     Start             =-->
<!--=====================================-->


<!--=====================================-->
<!--=   Preloader     Start             =-->
<!--=====================================-->
<!-- <div id="preloader"></div> -->

<!-- preloader -->
<div *ngIf="showProgressBar">
<mat-progress-bar mode="indeterminate" color="warn" ></mat-progress-bar></div>
<div class="main">
    <div>
  
    </div>
    <div class="overlay"></div>
    <div class="spanner">
      <div class="loader"></div>
    
    </div>
</div>
<div id="preloader">
    <div id="loading-center">
        <div id="loading-center-absolute">
            <img src="img/preloader.svg" alt="">
        </div>
    </div>
</div>
<!-- preloader-end -->

<!-- Scroll-top -->
<button class="scroll-top scroll-to-target" data-target="html">
    <i class="fas fa-angle-up"></i>
</button>
<!-- Scroll-top-end-->

<!--=====================================-->
<!--=            Header Start           =-->
<!--=====================================-->
<header class="header-style-two">

    <div id="sticky-header" class="menu-area">
        <div class="container custom-container" style="height: 65px;">
            <div class="row">
                <div class="col-12">
                    <!-- <div class="mobile-nav-toggler"><i class="fas fa-bars"></i></div> -->
                    <div class="menu-wrap">
                        <nav class="menu-nav show">
                            <div class="logo">
                                <a>
                                    <img src="assets/img/logo/PexcelLogo.png" alt="Logo">
                                </a>
                            </div>

                            <!-- <div class="logo d-lg-none d-sm-block mbl_img">
                                <a>
                                    <img src="assets/img/logo/Mobile-logo.png" alt="Mobile Logo">
                                </a>
                            </div> -->
                        
                               <!-- <div class="mobile-nav-toggler"></div> -->

                           

                            <div class="navbar-wrap main-menu d-none d-lg-flex">
                                <ul class="navigation">
                                   

                                    <li class="menu-item-has-children d-lg-none bg_clr"><a class="btn" style="width: 0px;"> <i class="fa fa-user user_icon"></i> </a>
                                        <ul class="submenu">
                                           
                                            <!-- <li class="active" style="cursor: pointer;"><a> Kuraku Vamsi </a></li>
                                            <li class="active" style="cursor: pointer;" [routerLink]="['/my-profile']"><a>My Profile</a></li>
                                            <li ><a> Settings </a></li>
                                            <li style="cursor: pointer;" [routerLink]="['/change-pwd']"><a> Change Password </a></li> -->
                                            <li class="active" (click)="clicksignIn()" style="cursor: pointer;"><a>Sign In</a></li>
                                               
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                          

                            <div class="navbar-wrap main-menu d-none d-lg-flex d-sm-flex" style="flex-grow: 0;">
                                <ul class="navigation" style="margin-top: 10px">
                                    <h2 style="margin-right: 10px;font-family: ui-sans-serif;">{{LogName}}</h2>
                                    <li class="header-btn2">
                                        <li class="header-btn2"><a id="HeaderLogName" class="btn" style="font-size: 12px;"> <i class="fa fa-user"></i></a>
                                        <ul class="submenu">
                                           
                                            <!-- <li class="active" style="cursor: pointer;"><a> Kuraku Vamsi </a></li>
                                            <li class="active" style="cursor: pointer;" [routerLink]="['/my-profile']"><a>My Profile</a></li>
                                            <li><a> Settings </a></li>
                                            <li style="cursor: pointer;" [routerLink]="['/change-pwd']"><a> Change Password </a></li> -->
                                            <li  *ngIf="!token"  class="active" (click)="clicksignIn()" style="cursor: pointer;"><a>Login</a></li>
                                            <li *ngIf="token"  class="active" [routerLink]="['/my-profile']" style="cursor: pointer;"><a>My Profile</a></li>
                                            <li  *ngIf="token"  class="active" (click)="clicksignOut()" style="cursor: pointer;"><a>Logout</a></li>
                                        </ul>
                                        
                                    </li>
                                </ul>
                            </div>


                        </nav>
                    </div>

                    <!-- Mobile Menu  -->
                    <!-- <div class="mobile-menu">
                        <div class="close-btn"><i class="fas fa-times"></i></div>

                        <nav class="menu-box">
                            <div class="nav-logo"><a [routerLink]="['/']"><img src="assets/img/logo/logo.png" alt="" title=""></a>
                            </div>
                            <div class="menu-outer">
                            </div>
                           
                        </nav>
                    </div>
                    <div class="menu-backdrop"></div> -->
                    <!-- End Mobile Menu -->



                </div>
            </div>
        </div>
    </div>

</header>